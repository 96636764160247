<template>
    <div style="background:#fff;">
        <a-layout-content style="padding:20px">
            <a-layout style="background:#fff;">
                <a-layout-header style="height:initial;background:#fff;padding: 0;">
                    <a-form layout="horizontal">
                        <a-form-item style="margin-bottom:10px;">

                        </a-form-item>
                    </a-form>
                </a-layout-header>
                <a-layout-content>
                    <div class="yj_list">
                        <a-table :columns="columns" bordered
                                 rowKey="ID"
                                 :data-source="tableData"
                                 :pagination="pagination"
                                 @change="pagerChange"
                                 class="yj-table-img" style=" min-height:calc(100vh - 314px)">

                            <span slot="imgs" slot-scope="text">
                                <img class="imgPreview" :src="text" alt="" />
                            </span>
                            <span slot="ClockCount" slot-scope="text,record">
                                <a @click="jumpshare(record)">{{record.ClockInCount?record.ClockInCount:0}}</a>
                            </span>
                        </a-table>
                    </div>
                </a-layout-content>
            </a-layout>
        </a-layout-content>
    </div>
</template>
<script>
    import http from "@/Plugin/Http.js";
    import util from "@/Plugin/util.js";
    export default {
        name: "",
        data() {
            return {
                columns: [
                    {
                        title: "头像",
                        dataIndex: "HeadImg",
                        align: "center",
                        scopedSlots: { customRender: "imgs" },
                        width: 100
                    },

                    {
                        title: "学员",
                        dataIndex: "NickName",
                    },

                    {
                        title: "手机",
                        dataIndex: "Phone",
                    },
                    {
                        title: "加入时间",
                        customRender: (text, item) => {
                            return util.TimeStamptoDateTime(item.InputTime, 'yyyy-MM-dd hh:mm:ss')
                        }
                    },
                    {
                        title: "打卡天数",
                        dataIndex: "ClockInCount",
                        scopedSlots: { customRender: "ClockCount" }
                    },

                ],
                tableData: [],
                MaterialID: "",
                pagination: {
                    total: 0,
                    defaultPageSize: 0,
                    current: 1,
                    showTotal: total => `共 ${total} 条数据`
                },
                //PromotionType: "-1"
            };
        },
        props: {//组件属性
        },
        methods: {//方法
            pagerChange: function (item) {
                var self = this;
                self.pagination.current = item.current;
                self.getTableData();
            },
             jumpshare: function (data) {
                var self = this;
                self.$router.push({
                    name: "ClockInDetail_List", query: {
                        MinterID: data.MInterID,
                        PersonID: data.ID,
                    }
                })
            },

            getTableData: function () {
                var self = this;
                var op = {
                    url: "/MarketingModule/MKTPunchClock/GetClockInPersonalList",
                    data: {
                        pageIndex: self.pagination.current,
                        MaterialID: self.$route.query.ID,

                    },
                    OnSuccess: function (data) {
                        self.tableData = data.data.Data;
                        self.pagination.total = data.data.Total;
                        self.pagination.defaultPageSize = data.data.RowCount;
                        self.pagination.current = data.data.PageIndex;
                    }
                };
                http.Post(op);
            },
        },
        computed: {//计算属性
        },
        watch: {//侦听属性
        },
        components: {//组件
        },
        beforeCreate: function () {//生命周期，创建前
        },
        created: function () {//生命周期，创建完成
        },
        beforeMount: function () {//生命周期，组装前
        },
        mounted: function () {//生命周期，组装完成
            this.MaterialID = this.$route.query.ID;
            this.getTableData();
        },
        beforeUpdate: function () {//生命周期，data更新前
        },
        updated: function () {//生命周期，data更新完成
        },
        beforeDestroy: function () {//生命周期，销毁前
        },
        destroyed: function () {//生命周期，销毁完成
        }
    };
</script>
<style scoped>
    .imgPreview {
        border-radius: 4px;
        width: 50px;
        height: 50px;
    }
</style>